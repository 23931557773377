import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import APIHelper from "../modules/APIHelper";
import {ListGroup, Row} from "react-bootstrap";

class StatusCard extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            apiData: {},
            servicesProblems: {},
            servicesUptime: {},
        };
    }

    componentDidMount() {
        APIHelper.fetchServices().then((data) => {
            this.setState({isLoaded: true, servicesUptime: data.services, servicesProblems: data.problems});
        });
    }

    render() {
        return (
            <Container fluid="sm" className="statusPage">
                <Card className="statusText">
                    <div className={this.state.servicesProblems.length === 0 ? "statusOk" : "statusWarning"}>
                        <Card.Body className="d-flex align-items-center">

                            <div className="dot is-big"></div>
                            <h3>{this.state.servicesProblems.length === 0 ? "All" : "Some"} systems <span
                                className="status-description">{this.state.servicesProblems.length === 0 ? "operational" : "not operational"}</span>
                            </h3>
                            <div style={{marginLeft: "40px"}}>
                                <ListGroup>
                                    {Object.entries(this.state.servicesProblems).map(([serviceName, {
                                        uptime,
                                        problems
                                    }], index) => (
                                        <ListGroup.Item>{this.state.servicesProblems[serviceName].name}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>

                        </Card.Body>
                    </div>
                </Card>
            </Container>
        );
    }
}

export default StatusCard;
