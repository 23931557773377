var helpers = {
    fetchServices: function() {
        var url = 'https://panel.dictumsoft.eu/api.php';

        return fetch(url)
            .then(function(response) {
                return response.json()
            })
            .then(function(json) {
                return json
            })
            .catch(function(error) {
                console.log('error', error)
            })
    }
}

module.exports = helpers;